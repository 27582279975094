<template>
  <div>
    <div class="form-check form-check-inline"
         v-for="option in options"
         :class="{
        'has-error': errors && errors.has(field)
    }">
      <input class="form-check-input"
             :value="option.id"
             v-model="radioVal"
             type="radio"
             :id="`radio-${option.id}`">
      <label class="form-check-label" :for="`radio-${option.id}`">
        {{ option.name }}
      </label>
      <AppError :errors="errors" :field="field" v-if="field && errors"/>
    </div>
  </div>
</template>

<script>
import AppError from '@/components/form/AppError';

export default {
  components: {
    AppError,
  },
  props: ['errors', 'value', 'field', 'options'],
  data() {
    return {
      radioVal: this.value,
    };
  },
  watch: {
    value() {
      this.radioVal = this.value;
    },
    radioVal() {
      this.$emit('input', this.radioVal);
    },
  },
};
</script>
