export class AlertChannelType {
  static EMAIL = 'EMAIL';

  static SMS = 'SMS';

  static PUSH = 'PUSH';

  static POST = 'POST';

  static POSITIONS_LIST = 'POSITIONS_LIST';

  static labels = {
    [this.EMAIL]: 'Email',
    [this.SMS]: 'SMS',
    [this.PUSH]: 'Push notification',
    [this.POST]: 'Wordpress alert post',
    [this.POSITIONS_LIST]: 'Wordpress positions list',
  };

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
