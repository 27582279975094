<template>
    <div>
        <div class="row mb-4">
            <div class="col-12">
                <p class="ps-3 mb-0">Webhooks integration</p>
                <p class="ps-3 mb-4 text-secondary">
                    <small>
                        Webhooks integration automatically keeps your subscribers, alerts and positions list up to date from a third party source.
                        <br>
                        Requires manual integration on the third-party application (eg Wordpress) by a developer.
                    </small>
                </p>
            </div>
        </div>
        <div class="row">
            <AppInput label="Subscribers webhook url"
                      :value="product.subscribers_webhook_url"
                      :errors="errors"
                      field="subscribers_webhook_url"
                      @change="updateProp({subscribers_webhook_url: $event})"
                      placeholder="Subscribers webhook url"
                      :readonly="true"
                      class="col-12 mb-2"/>
            <AppInput label="Alerts webhook url"
                      :value="product.alerts_webhook_url"
                      :errors="errors"
                      field="alerts_webhook_url"
                      @change="updateProp({alerts_webhook_url: $event})"
                      placeholder="Alerts webhook url"
                      :readonly="true"
                      class="col-12 mb-2"/>
            <AppInput label="Positions webhook url"
                      :value="product.positions_webhook_url"
                      :errors="errors"
                      field="positions_webhook_url"
                      @change="updateProp({positions_webhook_url: $event})"
                      placeholder="Positions webhook url"
                      :readonly="true"
                      class="col-12 mb-2"/>
            <AppInput label="Pages webhook url"
                      :value="product.pages_webhook_url"
                      :errors="errors"
                      field="pages_webhook_url"
                      @change="updateProp({pages_webhook_url: $event})"
                      placeholder="Pages webhook url"
                      :readonly="true"
                      class="col-12 mb-2"/>
            <AppInput label="Webhook token"
                      :value="product.webhook_token"
                      :errors="errors"
                      field="webhook_token"
                      @change="updateProp({webhook_token: $event})"
                      placeholder="Webhook token"
                      class="col-12 mb-2">
                <button class="btn btn-sm btn-link" @click="newSubscribersWebhookToken">Generate new token</button>
            </AppInput>
        </div>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import { uuidv4 } from '@/utils/helpers';

export default {
  props: {
    product: {},
    errors: {},
  },
  components: {
    AppInput,
  },
  methods: {
    updateProp(props) {
      this.$emit('update', props);
    },
    newSubscribersWebhookToken() {
      this.updateProp({ webhook_token: uuidv4() });
    },
  },
};
</script>
