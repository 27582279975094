<template>
    <form>
        <p class="ps-3 mb-2 me-3" v-if="channel.name">
            <strong>{{ channel.name }}</strong> alert channel
        </p>
        <div class="row">
            <AppInput label="Name"
                      :value="channel.name"
                      :errors="errors"
                      :field="`alert_channels.${index}.name`"
                      @change="updateProp({name: $event})"
                      placeholder="Name"
                      class="col-12 mb-2"
            />
            <AppSelect :value="channelType"
                       :errors="errors"
                       :options="types"
                       :field="`alert_channels.${index}.type`"
                       @input="updateChannelType($event)"
                       placeholder="Type"
                       class="col-12 mb-2"
            />
            <div v-if="channel.credentials">
                <div v-for="(value, field) of channel.credentials" :key="field">
                    <AppInput :label="field"
                              :value="value"
                              :errors="errors"
                              :field="`alert_channels.${index}.credentials.${field}`"
                              @change="updateCredentialProp({[field]: $event})"
                              :placeholder="field"
                              class="col-12 mb-2"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 py-2">
                <div class="ps-3">
                    <AppCheckbox :value="channel.is_enabled"
                                 :is-switch="true"
                                 :label="channel.is_enabled ? 'Enabled' : 'Disabled'"
                                 @input="updateProp({ is_enabled: $event })"/>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import AppSelect from '@/components/form/AppSelect';
import { AlertChannelType } from '@/store/models/alert-channel-type';
import AppCheckbox from '../form/AppCheckbox';

export default {
  props: ['channel', 'errors', 'index'],
  components: {
    AppCheckbox,
    AppSelect,
    AppInput,
  },
  computed: {
    channelType() {
      return this.types.find((type) => type.id === this.channel.type);
    },
    types() {
      return AlertChannelType.options();
    },
  },
  methods: {
    updateCredentialProp(props) {
      const credentials = { ...this.channel.credentials, ...props };
      this.updateProp({ credentials });
    },
    updateChannelType(val) {
      let credentials = null;

      const typeCredentials = {
        [AlertChannelType.EMAIL]: {
          api_key: null,
          segment_id: null,
          sender_id: null,
          unsubscribe_url: null,
        },
        [AlertChannelType.POST]: {
          url: null,
          username: null,
          app_password: null,
        },
        [AlertChannelType.PUSH]: {
          app_id: null,
          api_key: null,
        },
        [AlertChannelType.POSITIONS_LIST]: {
          url: null,
          username: null,
          app_password: null,
          open_positions_page_id: null,
          closed_positions_page_id: null,
        },
      };

      if (typeCredentials.hasOwnProperty(val.id)) {
        credentials = typeCredentials[val.id];
      }

      this.updateProp({
        type: val ? val.id : null,
        credentials,
      });
    },
    updateProp(props) {
      this.$emit('updated');

      this.$store.dispatch('alertChannels/alertChannelProps', {
        props,
        channelId: this.channel.id,
      });
    },
  },
};
</script>
