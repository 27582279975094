<template>
    <div>
        <div class="row mb-4">
            <div class="col-12">
                <p class="ps-3 mb-0">Services</p>
                <p class="ps-3 text-secondary">
                    <small>
                        Services are parts of your product which access can be limited to based on the users subscription.
                    </small>
                </p>
                <p class="ps-3 mb-4 text-secondary">
                    <small>
                        Example: {"beginner_signals": "Beginner Signals", "advanced_signals": "Advanced Signals"}
                    </small>
                </p>
            </div>
        </div>
        <div class="row">
            <AppTextarea label="Services"
                         :value="product.services ? JSON.stringify(product.services, null, 2) : ''"
                         @input="updateServices"
                         :errors="errors"
                         field="services"
                         class="col-12"/>
        </div>
    </div>
</template>

<script>
import AppTextarea from '@/components/form/AppTextarea';

export default {
  props: {
    product: {},
    errors: {},
  },
  components: {
    AppTextarea,
  },
  methods: {
    updateServices(val) {
      try {
        const services = JSON.parse(val);
        this.updateProp({ services });
      } catch (e) {
        console.log(e);
      }
    },
    updateProp(props) {
      this.$emit('update', props);
    },
  },
};
</script>
