<template>
    <div>
        <div class="row mb-4">
            <div class="col-12">
                <p class="ps-3 mb-0">Exchange integration</p>
                <p class="ps-3 mb-4 text-secondary">
                    <small>
                        Exchange integration automatically creates alert drafts for you in real time based on your actual
                        trades.
                        <br>
                        Trades filled on your exchange account will automatically be created as draft alerts with the exact
                        executed price and ticker.
                    </small>
                </p>
            </div>
        </div>
        <div class="row">
            <AppSelect :value="exchangeIntegration"
                       :errors="errors"
                       :options="exchangeIntegrations"
                       :allowEmpty="true"
                       field="integration"
                       @input="updateExchangeIntegration"
                       placeholder="Integration"
                       class="col-12 mb-2"
            />
        </div>
        <div class="row mb-3" v-if="product.exchange_credentials">
            <div v-for="(value, field) of product.exchange_credentials" :key="field">
                <AppInput v-if="field !== 'is_sandbox'"
                          :label="field"
                          :value="value"
                          :errors="errors"
                          :field="`exchange_credentials.${field}`"
                          @change="updateExchangeCredentialProp({[field]: $event})"
                          :placeholder="field"
                          class="col-12 mb-2"/>
            </div>
            <AppRadio class="mb-2"
                      :value="product.exchange_credentials.is_sandbox"
                      :options="sandboxOptions"
                      :errors="errors"
                      @input="updateExchangeCredentialProp({is_sandbox: $event})"
                      field="is_sandbox"
            />
        </div>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import AppSelect from '@/components/form/AppSelect';
import { ProductIntegration } from '@/store/models/product-integration';
import AppRadio from '@/components/form/AppRadio';

export default {
  props: {
    product: {},
    errors: {},
  },
  components: {
    AppRadio,
    AppInput,
    AppSelect,
  },
  computed: {
    exchangeIntegration() {
      return this.exchangeIntegrations.find((item) => item.id === this.product.exchange_integration);
    },
    exchangeIntegrations() {
      return ProductIntegration.options();
    },
    sandboxOptions() {
      return [
        {
          id: false,
          name: 'Live',
        },
        {
          id: true,
          name: 'Sandbox',
        },
      ];
    },
  },
  methods: {
    updateExchangeCredentialProp(props) {
      const exchange_credentials = { ...this.product.exchange_credentials, ...props };
      this.updateProp({ exchange_credentials });
    },
    updateExchangeIntegration(val) {
      let exchange_credentials = null;
      if (val.id === ProductIntegration.KUCOIN) {
        exchange_credentials = {
          key: null,
          secret: null,
          passphrase: null,
          is_sandbox: true,
        };
      }
      this.updateProp({
        exchange_integration: val ? val.id : null,
        exchange_credentials,
      });
    },
    updateProp(props) {
      this.$emit('update', props);
    },
  },
};
</script>
