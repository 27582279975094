<template>
    <div>
        <h5 class="ms-3 mb-4 text-secondary">Product settings</h5>
        <b-tabs pills align="center" content-class="mt-3 pt-4" v-if="product">
            <b-tab active>
                <template #title>
                    <span class="material-symbols-rounded navigation-drawer__icon me-0">
                        check
                    </span>
                    General
                </template>
                <div class="row">
                    <AppInput label="Product name"
                              :value="product.name"
                              :errors="errors"
                              @change="updateProp({name: $event})"
                              placeholder="Name"
                              class="col-12 mb-4"/>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <span class="material-symbols-rounded navigation-drawer__icon me-0">
                        check
                    </span>
                    Services
                </template>
                <ProductServicesForm :product="product" :errors="errors" @update="updateProp"/>
            </b-tab>
            <b-tab>
                <template #title>
                    <span class="material-symbols-rounded navigation-drawer__icon me-0">
                        check
                    </span>
                    Webhooks
                </template>
                <WebhooksSettingsForm :product="product" :errors="errors" @update="updateProp"/>
            </b-tab>
            <b-tab>
                <template #title>
                    <span class="material-symbols-rounded navigation-drawer__icon me-0">
                        check
                    </span>
                    Exchange
                </template>
                <ExchangeSettingsForm :product="product" :errors="errors" @update="updateProp"/>
            </b-tab>
            <b-tab>
                <template #title>
                    <span class="material-symbols-rounded navigation-drawer__icon me-0">
                        check
                    </span>
                    Alert channels
                </template>
                <div class="row mb-4">
                    <div class="col-12">
                        <p class="ps-3 mb-0">Alert channels</p>
                        <p class="ps-3 mb-4 text-secondary">
                            <small>
                                Alert channels are a way of sending alerts out, be it via SMS, email or other.
                                <br>
                                Add a channel and insert its credentials in order to enable sending alerts via that
                                channel.
                            </small>
                        </p>
                    </div>
                    <div class="col-12 mb-2"
                         v-for="(channel, index) in product.alert_channels"
                         :key="channel.id">
                        <AlertChannelForm :updated="channelUpdated" :channel="channel" :index="index" :errors="errors" class="mb-4"/>
                    </div>
                    <div class="col">
                        <button class="btn btn-outline-dark ms-3"
                                type="button"
                                @click="addNewAlertChannel">
                            Add channel
                        </button>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
        <div class="z-index-1 position-sticky bottom-0 bg-body p-3 border-top text-end">
            <button class="btn" type="button" v-if="dirty" @click="discardChanges">Discard changes</button>
            <button class="btn btn-primary ms-2" type="button" @click="save">Save settings</button>
        </div>
    </div>
</template>

<script>
import AppInput from '@/components/form/AppInput';
import { mapGetters } from 'vuex';
import NotificationService from '@/services/notification.service';
import Errors from '@/utils/errors';
import AlertChannelForm from '@/components/products/AlertChannelForm';
import { BTab, BTabs } from 'bootstrap-vue';
import WebhooksSettingsForm from '@/components/products/WebhooksSettingsForm';
import ExchangeSettingsForm from '@/components/products/ExchangeSettingsForm';
import ProductServicesForm from '@/components/products/ProductServicesForm';

export default {
  components: {
    ProductServicesForm,
    ExchangeSettingsForm,
    WebhooksSettingsForm,
    AlertChannelForm,
    AppInput,
    BTab,
    BTabs,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      dirty: false,
    };
  },
  computed: {
    ...mapGetters({
      product: 'products/product',
    }),
  },
  methods: {
    updateProp(props) {
      this.dirty = true;

      this.$store.dispatch('products/productProps', {
        props,
        productId: this.product.id,
      });
    },
    addNewAlertChannel() {
      this.$store.dispatch('alertChannels/addNewAlertChannel', {
        productId: this.product.id,
      });
    },
    async save() {
      this.loading = true;
      this.errors.clear();

      try {
        const res = await this.$store.dispatch('products/updateProduct');
        NotificationService.success(res.message);

        await this.$store.dispatch('liveAlerts/terminate');
        this.$store.dispatch('liveAlerts/init');

        this.dirty = false;
      } catch (err) {
        this.errors.set(err.response.data.errors);
      } finally {
        this.loading = false;
      }
    },
    channelUpdated() {
      this.dirty = true;
    },
    async discardChanges() {
      this.loading = true;
      await this.$store.dispatch('products/getProducts');
      this.loading = false;
      this.dirty = false;
    },
  },
};
</script>
