export class ProductIntegration {
  static KUCOIN = 'KUCOIN';

  static INTERACTIVE_BROKERS = 'INTERACTIVE_BROKERS';

  static labels = {
    [this.KUCOIN]: 'KuCoin',
    [this.INTERACTIVE_BROKERS]: 'Interactive Brokers',
  };

  static options = () => Object.keys(this.labels)
    .map((key) => ({
      id: key,
      name: this.labels[key],
    }));
}
