<template>
    <div>
        <div class="row justify-content-center">
            <ProductSettingsForm ref="form" class="col-xxl-8 col-lg-10"/>
        </div>
    </div>
</template>

<script>

import ProductSettingsForm from '@/components/products/ProductSettingsForm';

export default {
  components: { ProductSettingsForm },
  async beforeRouteLeave(to, from, next) {
    if (!this.$refs.form.dirty) {
      return next();
    }
    const confirmed = await this.$bvModal.msgBoxConfirm('You have unsaved changes. Do you want to discard them?', {
      title: 'Discard changes?',
      okTitle: 'Discard changes',
      okVariant: 'btn-link btn-link--primary',
      cancelTitle: 'Continue editing',
      cancelVariant: 'btn-link btn-link--primary',
      centered: true,
    });
    if (confirmed) {
      this.$store.dispatch('products/getProducts');
      next();
    }
  },
};
</script>
