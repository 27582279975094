<template>
    <div class="form-check" :class="{'form-switch': isSwitch}">
        <input type="checkbox"
               :id="inputId"
               class="form-check-input"
               :class="{
                     'is-invalid': errors && errors.has(field)
                 }"
               :checked="value"
               @input="$emit('input', $event.target.checked)">
        <label class="form-check-label" :for="inputId">
            {{ label }}
            <slot/>
        </label>
        <AppError v-if="errors" :errors="errors" :field="field"/>
    </div>
</template>

<script>
import AppError from '@/components/form/AppError';
import { uuidv4 } from '@/utils/helpers';

export default {
  components: {
    AppError,
  },
  props: ['errors', 'label', 'value', 'field', 'isSwitch'],
  methods: {
    inputId() {
      return `input-${uuidv4()}`;
    },
  },
};
</script>
